import Addon from "./components/Addon.vue";
import AddonSelector from "./components/AddonSelector.vue";
import FixedNumbers from "./components/FixedNumbers.vue";
import FixedNumberSelector from "./components/FixedNumberSelector.vue";
import InfoboxAddon from "./components/InfoboxAddon.vue";
import MandatoryAddon from "./components/MandatoryAddon.vue";
import Switchboard from "./components/Switchboard.vue";
import HuntingGroup from "./components/HuntingGroup.vue";
import HuntingGroupContainer from "./components/HuntingGroupContainer.vue";

const styles = mergeStylesOfComponents(
  Addon,
  AddonSelector,
  FixedNumberSelector,
  FixedNumbers,
  HuntingGroup,
  HuntingGroupContainer,
  InfoboxAddon,
  MandatoryAddon,
  Switchboard
);

Switchboard.styles = styles;

export default Switchboard;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
