<template>
  <div class="fixed-number__container">
    <div class="fixed-number__search-container">
      <telia-text-input
        class="fixed-number__search-input"
        type="tel"
        maxlength="20"
        :value="searchString"
        :label="t('switchboard.fixedNumber.phoneNumberSearchBeginningNumber')"
        @input="handleSearchInput"
        @keyup.enter.prevent="handleSearchSubmit"
      />
      <b2x-loading-button
        variant="primary"
        type="submit"
        @click="handleSearchSubmit"
        :is-loading="fetchingNumbers"
        >{{ t("switchboard.fixedNumber.searchButton") }}
      </b2x-loading-button>
      <telia-skeleton v-if="fetchingNumbers" class="fixed-number__skeleton" />
      <div v-if="showNumbersArray" class="fixed-number__numbers">
        <telia-radio-button
          v-for="phoneNumber in fixedPhoneNumbers"
          class="fixed-number__number"
          :value="phoneNumber"
          :key="phoneNumber"
          :checked="isSelected(phoneNumber)"
          @change="handlePhoneNumberSelect"
        >
          {{ phoneNumber }}
        </telia-radio-button>
      </div>
    </div>

    <telia-p class="fixed-number__reservation-notice" v-if="showNumbersArray">
      {{ t("switchboard.fixedNumber.phoneNumberReservationNotice") }}
    </telia-p>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { OnPhoneNumberSearchKey, OnPhoneNumberSelectKey } from "../@types/types";

type Props = {
  selectedNumber: string;
  fixedPhoneNumbers?: string[];
  fetchingNumbers: boolean;
  searchErrorMessage?: string;
};

const props = defineProps<Props>();

const searchString = ref("");
const lastEmittedSearch = ref("");

const showFieldError = computed(() => {
  return props.searchErrorMessage;
});

const showNumbersArray = computed(() => {
  return !props.fetchingNumbers && !showFieldError.value;
});

const onPhoneNumberSelect = inject(OnPhoneNumberSelectKey, () => {
  () => {
    console.log("Error in FixedNumbers: Could not find onPhoneNumberSelect function.");
  };
});
const onPhoneNumberSearch = inject(OnPhoneNumberSearchKey, () => {
  () => {
    console.log("Error in FixedNumbers: Could not find onPhoneNumberSearch function.");
  };
});

function handleSearchSubmit() {
  lastEmittedSearch.value = searchString.value;
  onPhoneNumberSearch(searchString.value);
}

function handleSearchInput(event: any) {
  searchString.value = event.target.value;
}

function handlePhoneNumberSelect(event: any) {
  onPhoneNumberSelect(event.target.value);
}

function isSelected(phoneNumber: string) {
  return phoneNumber === props.selectedNumber;
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.fixed-number {
  &__numbers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $telia-spacing-12 $telia-spacing-24;
    margin-block: $telia-spacing-12;
  }

  &__number {
    min-width: 140px;
  }

  &__reservation-notice {
    padding-block-start: $telia-spacing-12;
  }

  &__container {
    margin-block-start: $telia-spacing-16;
  }

  &__search-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $telia-spacing-12;
    align-items: flex-end;
  }

  &__search-input {
    flex: 1;
  }

  &__skeleton {
    width: 100%;
    height: $telia-spacing-24;
  }
}
</style>
