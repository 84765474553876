<template>
  <div :key="id" class="switchboard-addon-bundle__wrapper">
    <telia-heading variant="subsection-100" tag="h3">{{ name }}</telia-heading>
    <Addon
      v-for="addon in genericAddons"
      :id="addon.id"
      :name="addon.name"
      :disabled="addon.disabled"
      :is-selected="addon.isSelected"
      :price="addon.price"
      :key="addon.id"
      :on-change="onAddonSelect"
    />
    <MandatoryAddon
      v-for="addon in mandatoryAddons"
      :id="addon.id"
      :name="addon.name"
      :price="addon.price"
      :key="addon.id"
    />
    <InfoboxAddon
      v-for="addon in infoboxAddons"
      :name="addon.name"
      :id="addon.id"
      :key="addon.id"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";

import { AddonType, OnAddonSelectKey } from "../@types/types";
import Addon from "./Addon.vue";
import MandatoryAddon from "./MandatoryAddon.vue";
import InfoboxAddon from "./InfoboxAddon.vue";

type Props = {
  id: string;
  name: string;
  addons: AddonType[];
};

const props = defineProps<Props>();

const onAddonSelect = inject(OnAddonSelectKey, () => {
  console.log("Error in AddonSelector.vue: Could not find OnAddonSelect function.");
});

const genericAddons = computed(() => {
  return props.addons.filter((addon) => !addon.isMandatory && !addon.isInfobox);
});

const mandatoryAddons = computed(() => {
  return props.addons.filter((addon) => addon.isMandatory);
});

const infoboxAddons = computed(() => {
  return props.addons.filter((addon) => addon.isInfobox);
});
</script>

<style lang="scss" scoped>
.switchboard-addon-bundle {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
