import { InjectionKey } from "vue";

type AddonBundleType = {
  id: string;
  name: string;
  addons: AddonType[];
};

type AddonType = {
  id: string;
  name: string;
  productCode: string;
  disabled: boolean;
  isMandatory: boolean;
  isInfobox: boolean;
  isSelected: boolean;
  price: string;
};

type HuntingGroupType = {
  name: string;
  id: string;
  isSelected: boolean;
};

type FixedNumberBundleType = AddonBundleType & {
  fixedNumbers?: string[];
  selectedNumber: string;
  fetchingPhoneNumbers: boolean;
  hideSearchContainer?: boolean;
  searchErrorMessage?: string;
};

type OnChangeType = (id: string) => void;

const OnAddonSelectKey: InjectionKey<OnChangeType> = Symbol("onAddonSelect");
const OnFixedNumberSelectKey: InjectionKey<OnChangeType> = Symbol("onFixedNumberSelectKey");
const OnPhoneNumberSelectKey: InjectionKey<OnChangeType> = Symbol("onPhoneNumberSelect");
const OnPhoneNumberSearchKey: InjectionKey<OnChangeType> = Symbol("onPhoneNumberSearch");
const OnHuntingGroupSelectKey: InjectionKey<OnChangeType> = Symbol("onHuntingGroupSelect");

export {
  HuntingGroupType,
  AddonType,
  AddonBundleType,
  OnChangeType,
  FixedNumberBundleType,
  OnAddonSelectKey,
  OnFixedNumberSelectKey,
  OnPhoneNumberSearchKey,
  OnPhoneNumberSelectKey,
  OnHuntingGroupSelectKey,
};
