<template>
  <telia-checkbox
    :t-id="`hunting-group-${id}`"
    :key="id"
    :checked="isSelected"
    :value="name"
    @change="onHuntingGroupSelect?.(id)"
  >
    <telia-p>
      {{ name }}
    </telia-p>
  </telia-checkbox>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { HuntingGroupType, OnHuntingGroupSelectKey } from "../@types/types";

type Props = {
  name: string;
  id: string;
  isSelected: boolean;
};

const props = defineProps<Props>();
const onHuntingGroupSelect = inject(OnHuntingGroupSelectKey, () => {
  () => {
    console.log("Error in HuntingGroup: Could not find onHuntingGroupSelect function.");
  };
});
</script>

<style lang="scss" scoped></style>
