<template>
  <div :key="id" class="switchboard-addon-bundle__wrapper">
    <telia-heading variant="subsection-100" tag="h3">{{ name }}</telia-heading>
    <Addon
      v-for="addon in addons"
      :id="addon.id"
      :name="addon.name"
      :disabled="addon.disabled"
      :is-selected="addon.isSelected"
      :price="addon.price"
      :key="addon.id"
      :on-change="onAddonSelect"
    >
      <div v-if="showSearchContainer(addon)" class="switchboard-addon-bundle__fixed-number-wrapper">
        <FixedNumbers
          :fixed-phone-numbers="fixedNumbers"
          :selected-number="selectedNumber"
          :fetching-numbers="fetchingPhoneNumbers"
          :search-error-message="searchErrorMessage"
        />
        <telia-field-error
          class="switchboard-addon-bundle__error"
          v-if="addon.isSelected && showNoNumbersAvailableMessage"
          >{{ searchErrorMessage }}</telia-field-error
        >
      </div>
    </Addon>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import { AddonType, OnFixedNumberSelectKey } from "../@types/types";
import Addon from "./Addon.vue";
import FixedNumbers from "./FixedNumbers.vue";
type Props = {
  id: string;
  name: string;
  addons: AddonType[];
  fixedNumbers?: string[];
  selectedNumber: string;
  fetchingPhoneNumbers: boolean;
  hideSearchContainer?: boolean;
  searchErrorMessage?: string;
};

const props = defineProps<Props>();

const showNoNumbersAvailableMessage = computed(() => {
  return props.searchErrorMessage;
});

const showSearchContainer = (addon: AddonType) => {
  return addon.isSelected && !props.hideSearchContainer && !addon.disabled;
};

const onAddonSelect = inject(OnFixedNumberSelectKey, () => {
  console.log("Error in FixedNumberSelector.vue: Could not find OnFixedNumberSelect function.");
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.switchboard-addon-bundle {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-12;
  }
  &__error {
    margin: $telia-spacing-12 0;
  }
  &__fixed-number-wrapper {
    border-bottom: 1px solid $telia-gray-300;
    padding-block-end: $telia-spacing-24;
  }
}
</style>
