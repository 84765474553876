<template>
  <div class="switchboard__wrapper">
    <FixedNumberSelector v-if="fixedNumberBundle" v-bind="fixedNumberBundle" />
    <AddonSelector v-for="addonBundle in addonBundles" :key="addonBundle.id" v-bind="addonBundle" />
    <HuntingGroupContainer v-if="hasHuntingGroups" :hunting-groups="huntingGroups" />
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from "vue";
import {
  OnHuntingGroupSelectKey,
  type AddonBundleType,
  type HuntingGroupType,
  OnPhoneNumberSearchKey,
  OnPhoneNumberSelectKey,
  OnAddonSelectKey,
  FixedNumberBundleType,
  OnFixedNumberSelectKey,
} from "../@types/types";
import AddonSelector from "./AddonSelector.vue";
import HuntingGroupContainer from "./HuntingGroupContainer.vue";
import FixedNumberSelector from "./FixedNumberSelector.vue";
import { translateSetup } from "../locale";

translateSetup(["mybusiness"]);

type Props = {
  addonBundles: AddonBundleType[];
  huntingGroups: HuntingGroupType[];
  fixedNumberBundle?: FixedNumberBundleType;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "select-addon", addonId: string): void;
  (e: "select-fixed-number-addon", addonId: string): void;
  (e: "select-fixed-phonenumber", phoneNumber: string): void;
  (e: "search-fixed-phonenumber", searchString: string): void;
  (e: "select-hunting-group", huntingGroupId: string): void;
}>();

provide(OnAddonSelectKey, (addonId: string) => {
  emit("select-addon", addonId);
});

provide(OnFixedNumberSelectKey, (addonId: string) => {
  emit("select-fixed-number-addon", addonId);
});

provide(OnPhoneNumberSelectKey, (phoneNumber: string) => {
  emit("select-fixed-phonenumber", phoneNumber);
});

provide(OnPhoneNumberSearchKey, (searchString: string) => {
  emit("search-fixed-phonenumber", searchString);
});

provide(OnHuntingGroupSelectKey, (huntingGroupId: string) => {
  emit("select-hunting-group", huntingGroupId);
});

const hasHuntingGroups = computed(() => {
  return props.huntingGroups.length > 0;
});
</script>
<style lang="scss">
*,
*:before,
*:after {
  box-sizing: inherit;
}
</style>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.switchboard__wrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
</style>
